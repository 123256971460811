.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.user {
  background-color: #c2e5834f;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.not-comatible-formulas-wrapper {}

.not-comatible-formula {
  background-color: #f2ecd4;
  display: inline-block;
  padding: 0.1rem 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
  margin: 0 0.2rem 0.1rem;
}

.not-comatible-formula-versions {}

.not-comatible-formula-version-label {
  background-color: #6f0404;
  display: inline-block;
  padding: 0rem 0.2rem;
  font-size: 0.7rem;
  color: white;
  border: 1px solid #a2a2a2;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
}

.MuiFileInput-TextField label {
  cursor: pointer;
}

.preFormula {
  background-color: black;
  color: white;
  padding: 0.1rem 0.5rem;
  border-radius: 0.2rem;
  border: 1px dashed white;
  margin: 0.1rem;
}

.active,
.active>div {
  background-color: rgb(238, 244, 226);
}

.rowCount {
  background-color: purple;
  color: white;
  height: 1rem;
  min-width: 1rem;
  border-radius: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.2rem;
  box-shadow: 1px 1px 2px 0px #dedede;
  margin-left: 0.2rem;
}

.columnName {
  background-color: #ff7800;
  color: white;
  height: 1rem;
  min-width: 1rem;
  border-radius: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0.3rem 0.5rem 0.3rem;
  box-shadow: 1px 1px 2px 0px #dedede;
  margin-left: 0.2rem;
}

.btnGroupIncrementer {
  height: 20px;
}

.btnGroupIncrementer button svg {
  font-size: 0.7rem;
}

.btnGroupIncrementer button {
  border-color: #adadad;
  color: black;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}